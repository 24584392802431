import './Components-CSS/EvstateDashboard.css';
import Toggle from './Toggle';
import Slider from "./Slider";
import React from 'react';
import RadioIconInputs from "./RadioIconInputs";
import BarChart from "./BarChart";
import {useState,useRef,useEffect} from "react";
import {useNavigate } from "react-router-dom";
import {Auth} from "aws-amplify";

const EvstateDashboard = (props) => {
    const navigate = useNavigate ();

    useEffect(() => {

        return () => {
            clearInterval(interv);
            //alert(interv);
        };
    }, []);


    var cook=document.cookie.split(';');
    var username_cook = cook[0].split(' ')[0];
    var password_cook = cook[0].split(' ')[1];

    useEffect( () => {

        const cookies = document.cookie.split(';');
        const username = cookies[0].split(' ')[0];
        const password = cookies[0].split(' ')[1];
        //console.log('before',document.cookie);

        if (username && password) {
            secure(username,password);
        } else {
            navigate('/portal/enterp');
        }

    });



    const secure= async(username,password)=> {
        try {
            const response= await Auth.signIn(username, password);
            console.log('Request successful:', response);
        }
        catch (err)
        {
            navigate('/portal/enterp');
            //console.log(err);

        }
    }


    const logouthandle= ()=> {
        document.cookie = username_cook+' '+password_cook+'; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        navigate('/portal/enterp');
    }



    const [userData,setUserData]=useState({
labels:["1","2","3","4","5","6"],
        datasets:[{
    label:"sample",
    data:[1 ,2 ,3 ,4 ,5 ,6]
        }]
    });

    const childRef = useRef();
    const childRef2 = useRef();

    const [interv,setInterv]=useState(0);

    const P = useRef();
    const Q = useRef();
    const Vrms = useRef();
    const Irms = useRef();
    const Vthd = useRef();
    const Ithd = useRef();
    const PF = useRef();

    function refreshSet(arg){
            const refr = parseInt(arg.split("-")) * 1000;
            childRef.current.updateData(refr);
            childRef2.current.updateData(refr);
            RefreshParameters(refr);

    }

    function handleRefresh(){
        childRef.current.updateData(1000);
        childRef2.current.updateData(1000);
        RefreshParameters(1000);
    }

    function stopRefresh(){
        childRef.current.stopUpdate();
        childRef2.current.stopUpdate();
        clearInterval(interv);
    }


    function RefreshParameters(refRate){
        console.log("RefreshParameters");
        clearInterval(interv);

        const Myint=setInterval(() => {
            console.log(Myint);
if(P.current) {
    P.current.value = (5.54 + Math.random() / 10).toFixed(4);
    Q.current.value = (0.64 + Math.random() / 100).toFixed(4);
    Vrms.current.value = (235 + Math.random() * 3).toFixed(4);
    Irms.current.value = (35.4 + Math.random() * 2).toFixed(4);
    Vthd.current.value = (1.5 + Math.random() / 10).toFixed(4);
    Ithd.current.value = (3.2 + Math.random() / 10).toFixed(4);
    PF.current.value = (0.98 + Math.random() / 100).toFixed(4);
}
}, refRate);


        setInterv(Myint);
    }


    return (
        <>
        <div className="row m-2 mt-4 justify-content-center " ><div className="col-10 text-left  "   >
            <button onClick={logouthandle} className="btn btn-danger" style={{width:"100px", marginLeft:"-30px"}} >Logout</button></div> </div>
        <div className="row justify-content-center  EvstateDashboard " >

          <div className="col-5 text-left  m-1  EvstateDashboard-col "  style={{minWidth:"350px"}}>
              <div className="row p-4 m-1   ">
                  <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Charger ID</label>
                  <input type="email" value="50-EB-G6-D0-B3-D9" readOnly className="form-control m-2"
                         style={{ width:"100%",fontWeight:"700" }}
                         id="exampleInputEmail1"/>
              </div>

              <div className="row p-4 m-1   justify-content-center">

                  <div className="col-4 mt-4 ">

                  <Toggle/>

                  </div>


                  <div className="col-4  ">
                      <label htmlFor="exampleInputEmail1" className=" mb-0">&nbsp;&nbsp;Charging</label>
                      <input type="email" readOnly className="form-control m-2"
                             style={{ width:"50px", height:"50px", borderRadius:"50%", borderWidth:"5px",
                      backgroundColor:"red"}}
                             id="exampleInputEmail1"/>
                  </div>

                  <div className="col-4  ">
                      <label htmlFor="exampleInputEmail1" className=" mb-0">&nbsp;&nbsp;&nbsp;V2X</label>
                      <input type="email" readOnly className="form-control m-2"
                             style={{ width:"50px", height:"50px", borderRadius:"50%", borderWidth:"5px",
                                 backgroundColor:"lightgray"}}
                             id="exampleInputEmail1"/>
                  </div>

              </div>

          </div>
          <div className="col-5 text-center m-1  EvstateDashboard-col   " style={{minWidth:"550px",}} >

                <div className="row  justify-content-center ">
                    <div className="col-5">
                    <label  className="m-2 ">Charger Current Limit (A)</label>
                        <Slider/>
                    </div>
                    <div className="col-5">
                        <label  className="m-2 ">Refresh Rate</label>
                        <RadioIconInputs refreshSet={refreshSet}/>

                        <button onClick={stopRefresh} className="btn btn-danger m-2 mt-4 mb-4 " >Stop Refresh</button>
                    </div>

                </div>

            </div>
          <div className="col-10  m-1 EvstateDashboard-col " >
                <div className="row p-4 ">
                    <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">P(kW)</label>
                        <input ref={P} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>


                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Vrms(V)</label>
                        <input ref={Vrms} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>

                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Voltage THD (%)</label>
                        <input ref={Vthd}  type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>

                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Power factor - PF</label>
                        <input ref={PF} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>
                    </div>
                    <div className="col-6">
                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Q(kVar)</label>
                        <input ref={Q} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>


                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Irms(A)</label>
                        <input ref={Irms} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>

                        <label htmlFor="exampleInputEmail1" className="m-2 mb-0">Current THD (%)</label>
                        <input ref={Ithd} type="email" readOnly className="form-control m-2" style={{ width:"100%" }}
                               id="exampleInputEmail1"/>
                        <div className="row ">
                            <div className="col-6 ">
                                <label htmlFor="exampleInputEmail1" className="m-2 mb-0">&nbsp;&nbsp; Lag</label>
                                <input type="email" readOnly className="form-control m-2 "
                                       style={{ width:"50px", height:"50px", borderRadius:"50%", borderWidth:"5px"  }}
                                       id="exampleInputEmail1"/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="exampleInputEmail1" className="m-2 mb-0">&nbsp;&nbsp;Lead</label>
                                <input type="email" readOnly className="form-control m-2"
                                       style={{ width:"50px", height:"50px", borderRadius:"50%", borderWidth:"5px",
                                           backgroundColor:"lightgreen"}}
                                       id="exampleInputEmail1"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-10  m-1 EvstateDashboard-col justify-content-center "
                 style={{backgroundColor:"white", maxHeight:"1400px"}} >
                        <BarChart typee="volt" ref={childRef} chLegend="Voltage Harmonics" chXtitle="# Harmonic" chYtitle="Amplitude"  />
            </div>
            <div className="col-10  m-1 mb-5 EvstateDashboard-col justify-content-center "
                 style={{backgroundColor:"white", maxHeight:"1400px"}} >
                <BarChart typee="curr" ref={childRef2} chLegend="Current Harmonics" chXtitle="# Harmonic" chYtitle="Amplitude"  />
            </div>



        </div>

        </>
    );
}

export default EvstateDashboard;