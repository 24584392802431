import { Component, createRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'
import 'bootstrap/dist/css/bootstrap.min.css'
import React,{ useRef,forwardRef,useImperativeHandle } from 'react';


const BarChart = forwardRef ((props,ref) =>
{

        useEffect(() => {
            // This code will be executed when the component mounts

            return () => {
                clearInterval(interv);
            };
        }, []);


    // Assign the child method to the ref
    useImperativeHandle(ref, () => ({
        updateData,
        stopUpdate
    }));



    const chart = useRef(0);

    const [chartData,SetChartData]=useState(
        {
            labels:[2,3,4,5,6,7,8,9,10,11,12,13],
            datasets:
                [
                    {label:props.chLegend,
                        data:[0,0,0,0,0,0,0,0,0,0,0,0,0],
                        pointRadius:0,
                        borderWidth:2
                    }
                ]
        }
    );

    const [optionsData,Setoptions]=useState(
        {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: 'rgb(255, 99, 132)',
                        font:{
                            size:16,
                            weight:600
                        }
                    }
                }
            },
            scales: {
                y: {
                    title: {
                        display: true,
                        text: props.chYtitle,
                        font:
                            {
                                size:14,
                                weight:700
                            }
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: props.chXtitle,
                        font:
                            {
                                size:14,
                                weight:700
                            }
                    }
                }
            }
        }
    );

   /* const [refRate,SetrefRate]=useState(1000);*/

    const [interv,setInterv]=useState(0);


    return (
        <>
                <Bar data={chartData} options={optionsData} ref={chart} />

        </>
    );




    function updateData(refRate){
        var val = 0.05;
        var valper=0.001;

        clearInterval(interv);
        const newChartData=chartData;

        const Myint=setInterval(() => {
           // console.log(Myint);
            for(var i=0;i<12;i++)
            {
                if(i===0|| i===2 || i===4 || i===6 || i===8 || i===10) {

                    val = 0.05
                    valper=0.01;
                }
                else
                {
                    if(props.typee==="volt")
                    val = 0.7
                    else
                    val = 1.7;
                    valper=0.1;
                }
                newChartData.labels[i]=i+2;
                newChartData.datasets[0].data[i]=val/i+valper*Math.random();
            }
            SetChartData(newChartData);
            if(chart.current)
            chart.current.update();
        }, refRate);

        setInterv(Myint);

    }

    function stopUpdate(){
        clearInterval(interv);
    }
});



export default BarChart;
