import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const Stability = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_stability" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Power System Stability Analysis</h2>
                <br/>
                At UltimGrid, our power system stability analysis services focus on ensuring the
                continuous operation of power systems during normal and disturbed conditions. Our experts use advanced
                simulation tools and techniques to assess the dynamic performance of power systems and microgrids,
                identify potential stability issues, and recommend optimal control and protection strategies. Our
                stability analysis services include transient stability, voltage stability, and small-signal stability
                analysis, tailored to the specific requirements of our clients and their power systems.

                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default Stability;
