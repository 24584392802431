import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const LoadFlowAnalysis = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_loadflow" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Power Flow and Short Circuit Analysis</h2>
                <br/>
                At UltimGrid, we provide comprehensive load flow analysis services to help utilities,
                grid operators, and industrial clients understand the power flow within their electrical networks. Our
                experts use state-of-the-art software tools to model and simulate power systems, analyze the flow of
                active and reactive power, and identify potential bottlenecks and constraints. By providing valuable
                insights into network performance, our power flow analysis services enable clients to optimize their
                power systems, enhance operational efficiency, and ensure reliable and secure energy supply.
                <br/><br/>
                The fault analysis aims to identify and assess the impact of electrical faults on power system
                performance and determine the short-circuit stresses on the power equipment. Our team of experts uses
                advanced simulation and analytical tools to evaluate fault scenarios, determine fault current levels,
                and assess the effectiveness of protection systems. Our fault analysis services help clients to improve
                the reliability and safety of their power systems, minimize the risk of equipment damage, and prevent
                service interruptions due to electrical faults.

                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default LoadFlowAnalysis;
