import React, { useState } from 'react';
import './Components-CSS/Toggle.css';

const Toggle = () => {
    const [isOn, setIsOn] = useState(false);

    const handleToggle = () => {
        setIsOn(prevState => !prevState);
    };

    return (
        <div
            className={`toggle-container ${isOn ? 'on' : ''}`}
            onClick={handleToggle}
        >
            <div className="toggle-handle">
                {isOn ? 'ON' : 'OFF'}
            </div>
        </div>
    );
};

export default Toggle;