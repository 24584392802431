import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {animated} from "@react-spring/web";
import {useEffect} from "react";

const EMTAnalysis = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
        <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
            <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Electromagnetic Transient Analysis</h2>
            <br/>
            Electromagnetic transients include fluctuations in voltage and current that occur in a power system due
            to sudden changes in the electrical network, such as switching operations, lightning strikes,
            or faults. These transients can cause equipment failure, affect power quality, and even lead
            to power outages.<br/><br/>
            UltimGrid specializes in wide variety of detailed electromagnetic transient (EMT) studies in power
            systems. The company employs a team of highly skilled professionals who are experienced in using
            advanced software and analytical tools to conduct detailed simulations of electrical systems under
            various transient conditions. Through these analyses, UltimGrid's team can identify potential issues
            and recommend solutions to improve the system's reliability and efficiency. The company's services
            cover a wide range of transient studies, including:<br/><br/>
            <ul>
            {bulletPoints.map((point, index) => (
                <animated.li key={index}>{point}</animated.li>
            ))}
            </ul>
            <br/><br/>
            UltimGrid's services are essential for power system operators, planners, and designers to
            ensure the safe and reliable operation of power systems. By identifying potential issues and
            recommending solutions, UltimGrid helps its clients to minimize downtime, reduce equipment damage,
            and improve power quality, ultimately leading to cost savings and increased customer satisfaction.
        </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default EMTAnalysis;
