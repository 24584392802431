import React,{useEffect} from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";

import {animated} from "@react-spring/web";

const EV = () => {
    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EV'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Implementing smart charging stations with real-time usage monitoring and dynamic load balancing to maximize efficiency.',
        'Developing mobile applications for EV users to locate nearby charging stations, reserve charging slots, and process payments.',
        'Integrating renewable energy sources, such as solar and wind, to power the charging infrastructure and further reduce the carbon footprint.',
        'Offering advanced fleet management solutions for businesses, enabling them to effectively monitor and manage their EV fleets and charging infrastructure.',
        'Collaborating with local governments and urban planners to design and develop future-proof charging networks that can accommodate the increasing number of EVs on the roads.',
    ];

    return (
        <div className="row_costume_evCharging " style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EV" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >EV Charging Solutions</h2>
                <br/>
                UltimGrid Solutions specializes in the design, development, and implementation of electric
                vehicle (EV) charging infrastructure, catering to the growing demand for clean and efficient
                transportation. Our team of experts is dedicated to providing end-to-end solutions, from site
                selection and planning to equipment installation, system integration, and ongoing maintenance.
                We work closely with clients to develop customized charging infrastructure strategies that meet
                their specific needs and requirements, while also ensuring maximum operational efficiency,
                sustainability, and user satisfaction. By staying at the forefront of the latest advancements
                in EV charging technology and industry best practices, we aim to create a seamless and reliable
                charging experience for EV users, ultimately contributing to a more sustainable and
                environmentally-friendly future for all.<br/><br/>
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}}>Our Goals</h2>
                <br/>
                <ul>
                    {bulletPoints.map((point, index) => (
                        <animated.li key={index}>{point}</animated.li>
                    ))}
                </ul>
            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default EV;
