import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const BlockchainGrid = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_IOT" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Blockchain-Based Platform for Transactive Energy</h2>
                <br/>
                UltimGrid is at the forefront of integrating blockchain technology into grid
                transactions, paving the way for a more decentralized, secure, and transparent energy market. Our
                blockchain solutions enable seamless peer-to-peer energy trading, allowing consumers to sell their
                excess energy directly to other consumers without the need for intermediaries. By automating the
                validation, recording, and settlement of energy transactions, our blockchain-based grid platform
                enhances efficiency, reduces transaction costs, and fosters greater trust and collaboration among market
                participants.
                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default BlockchainGrid
