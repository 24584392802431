import React, {Component, useState} from 'react';
import './index.css';
import Navboot_func from "./Nav_boot";
import Home from './components/home'
import {Route, Routes} from "react-router-dom";
import Footer from "./components/footer";
import {Amplify} from "aws-amplify";
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import AI from "./routes/AI";
import QuantumComputing from "./routes/QuantumComputing";
import LoadFlowAnalysis from "./routes/LoadFlowAnalysis";
import StabilityAnalysis from "./routes/StabilityAnalysis";
import ResilienceAnalysis from "./routes/ResilienceAnalysis";
import DemandResponse from "./routes/DemandResponse";
import GMDMitigation from "./routes/GMDMitigation";
import BlockchainGrid from "./routes/BlockchainGrid";
import BlockchainEV from "./routes/BlockchainEV";
import EVChargingInfrastructure from "./routes/EVChargingInfrastructure";
import DigitalTwinSolutions from "./routes/DigitalTwinSolutions";
import SoftwareEnabledHardware from "./routes/SoftwareEnabledHardware";
import EMTAnalysis from "./routes/EMTAnalysis";
import Enterp from "./components/enterp"
import EvstateDashboard from "./components/EvstateDashboard"
import awsExports from "./aws-exports";
import ParticleImage from "./components/ParticleImage";


Amplify.configure(awsExports);

function App() {

    /* <Navboot_func style={{ zIndex: 9999 }} />  */

    const [ isAuth,setIsAuth ]= useState(false)

        return (
            <div>

                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/PowerSystemAnalysis/EMT" element={<EMTAnalysis/>} />
                    <Route path="/PowerSystemAnalysis/PowerFlow" element={<LoadFlowAnalysis/>} />
                    <Route path="/PowerSystemAnalysis/Stability" element={<StabilityAnalysis/>} />
                    <Route path="/PowerSystemAnalysis/Resilience" element={<ResilienceAnalysis/>} />
                    <Route path="/PowerSystemAnalysis/DemandResponse" element={<DemandResponse/>} />
                    <Route path="/PowerSystemAnalysis/GMD" element={<GMDMitigation/>} />
                    <Route path="/DistributedEnergyMarkets/BlockchainGrid" element={<BlockchainGrid/>} />
                    <Route path="/DistributedEnergyMarkets/BlockchainEV" element={<BlockchainEV/>} />
                    <Route path="/ElectrifiedTransportation/EVCharging" element={<EVChargingInfrastructure/>} />
                    <Route path="/ElectrifiedTransportation/DigitalTwin" element={<DigitalTwinSolutions/>} />
                    <Route path="/ElectrifiedTransportation/SoftwareEnabledHardware" element={<SoftwareEnabledHardware/>} />
                    <Route path="/AIandQuantum/AI" element={<AI/>} />
                    <Route path="/AIandQuantum/QuantumComputing" element={<QuantumComputing/>} />
                    <Route path="/portal/enterp" element={<Enterp  />} />
                    <Route path="/dashboard" element={<EvstateDashboard checkk={isAuth}   />} />
                    <Route path="/" element={<Home/>} />
                </Routes>

            </div >
        );
}

export default App;