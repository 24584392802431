import React, { useState } from 'react';

const Slider = () => {
    const [value, setValue] = useState(45);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
    };

    return (
        <div>
            <input
                type="range"
                min={0}
                max={50}
                value={value}
                onChange={handleChange}
            />
            <input
                className="form-control m-2"
                type="number"
                min={0}
                max={50}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};

export default Slider;