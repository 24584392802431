import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const SoftwareEnabledHardware = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_hardware" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Software Enabled Hardware</h2>
                <br/>
                UltimGrid Solutions is a leading provider of software-enabled solutions for the power
                and electric vehicle (EV) industries, revolutionizing the way energy systems and transportation
                infrastructure are designed, managed, and optimized. Our team of dedicated professionals combines deep
                domain expertise with cutting-edge software technologies to deliver innovative solutions that enhance
                efficiency, reliability, and sustainability across the entire energy value chain. By harnessing the
                power of advanced analytics, artificial intelligence, and data-driven insights, we enable our clients to
                make smarter decisions, streamline operations, and unlock new business opportunities. From grid
                management and energy optimization to EV charging infrastructure and fleet management, our
                software-enabled solutions empower stakeholders in the power and EV sectors to stay ahead of the curve
                and drive a more sustainable, connected, and intelligent future.


                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default SoftwareEnabledHardware;
