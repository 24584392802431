import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./App.css";
import React from "react";
import {Link} from "react-router-dom";
import ParticleImage from "./components/ParticleImage";

function Navboot_func() {
    return (
    <Navbar sticky bg="light" variant="light" expand="lg" >
            <Container>
                <Navbar.Brand>
                    <Nav.Link as={Link} to="/" >
                        <span className="myCompany" >UltimGrid</span>
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown title="Power System Analysis" id="basic-nav-dropdown">
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/EMT">Electromagnetic Transients Analysis</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/PowerFlow">Power Flow and Short-Circuit Analysis</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/Stability">Power System Stability Analysis</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/Resilience">Power System Resilience Analysis</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/DemandResponse">Demand Response</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/PowerSystemAnalysis/GMD">GMD Mitigation</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Distributed Energy Markets" id="basic-nav-dropdown">
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/DistributedEnergyMarkets/BlockchainGrid">Blockchain-Based Platform for Transactive Energy</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/DistributedEnergyMarkets/BlockchainEV">Blockchain in EV Charging Transactions</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Electrified Transportation" id="basic-nav-dropdown">
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/ElectrifiedTransportation/EVCharging">EV Charging Infrastructure</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/ElectrifiedTransportation/DigitalTwin">Digital Twin Solutions</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/ElectrifiedTransportation/SoftwareEnabledHardware">Software Enabled Hardware</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="AI & Quantum Computing" id="basic-nav-dropdown">
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/AIandQuantum/AI">Artificial Intelligence</Nav.Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/AIandQuantum/QuantumComputing">Quantum Computing</Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="EV Charger state portal" id="basic-nav-dropdown">
                            <NavDropdown.Item >
                                <Nav.Link as={Link} to="/dashboard">Login Portal </Nav.Link>
                            </NavDropdown.Item>
                        </NavDropdown>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navboot_func;

