import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,document.getElementById("root"));
reportWebVitals();
