import React, {useEffect, useState} from 'react';
import './Components-CSS/style.css';
import {useNavigate } from "react-router-dom";
import {useRef} from "react";
import {Auth} from "aws-amplify";
import preLoader from "./PreLoader";
import PreLoader from "./PreLoader";

const Enterp =  (props) => {

    const [lmode,setLmode]=useState({display:"block"})


    useEffect( () => {

        const cookies = document.cookie.split(';');
        const username = cookies[0].split(' ')[0];
        const password = cookies[0].split(' ')[1];
        //console.log('before',document.cookie);

        if (username && password) {
            secure(username,password);
        } else {

        }

    }, []);



    const secure= async(username,password)=> {
        try {
            await Auth.signIn(username, password);
            navigate('/dashboard');
        }
        catch (err)
        {

        }
    }


    const navigate = useNavigate ();

    const username = useRef(null);
    const password = useRef(null);

    const handleSubmit= async (e)=>{
       setLmode({display: "none" });
    e.preventDefault();
        //navigate('/dashboard');
       // await Auth.signIn()
        //console.log(username.current.value);
       // console.log(password.current.value);

try {
    await Auth.signIn(username.current.value, password.current.value);
    console.log('success!')
    document.cookie =username.current.value+' '+password.current.value+'; path=/';
    navigate('/dashboard');
}
catch (err){
    console.log(err);
    setLmode({display: "block" });
}
    }

    //********************** return

    return (
        <>
            {

                lmode.display==="none" && (
                    <PreLoader/>
                )

            }

        <div style={lmode} >

            <div  id="log_container" className="d-flex justify-content-center align-items-center"
                 style={{height: '100vh'}}>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <input ref={username} placeholder="Email / Username" required/>
                    </div>

                    <div className="form-field">
                        <input ref={password} type="password" placeholder="Password" required/>



                    </div>

                    <div className="form-field">
                        <button className="btn" type="submit">Log in</button>
                    </div>
                </form>
            </div>




        </div>
</>
    );
}

export default Enterp;