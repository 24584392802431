import React from 'react';
import './Components-CSS/PreLoader.css';

const PreLoader = () => {
    return (
        <div className="pre-loader">
            <div className="spinner"></div>
        </div>
    );
};

export default PreLoader;