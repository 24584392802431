import React, { useState } from 'react';

const RadioIconInputs = (props) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        props.refreshSet(event.target.value);

    };

    return (
        <div>
            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="1-sec"
                    name="time-interval"
                    value="1-Sec"
                    checked={selectedValue === '1-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="1-sec">1-Sec</label>
            </div>

            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="2-sec"
                    name="time-interval"
                    value="2-Sec"
                    checked={selectedValue === '2-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="2-sec">2-Sec</label>
            </div>

            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="5-sec"
                    name="time-interval"
                    value="5-Sec"
                    checked={selectedValue === '5-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="5-sec">5-Sec</label>
            </div>

            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="10-sec"
                    name="time-interval"
                    value="10-Sec"
                    checked={selectedValue === '10-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="10-sec">10-Sec</label>
            </div>

            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="30-sec"
                    name="time-interval"
                    value="30-Sec"
                    checked={selectedValue === '30-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="30-sec">30-Sec</label>
            </div>

            <div style={{ margin: '5px' }}>
                <input
                    type="radio"
                    id="60-sec"
                    name="time-interval"
                    value="60-Sec"
                    checked={selectedValue === '60-Sec'}
                    onChange={handleChange}
                />
                <label htmlFor="60-sec">60-Sec</label>
            </div>
        </div>
    );
};

export default RadioIconInputs;