import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const QuantumComputing = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_QuantumComputing" style={{ minHeight: '100vh' }} >
            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Quantum Computing</h2>
                <br/>
                UltimGrid Solutions is an industry leader in the integration of Quantum Computing
                technologies into power systems, unlocking new possibilities for efficiency and reliability in energy
                management. Our dedication to creating a sustainable and resilient energy future drives us to explore
                the potential of Quantum Computing in solving complex optimization problems, securing critical
                infrastructure, and revolutionizing grid management. Our team of experts focuses on developing and
                implementing innovative solutions that leverage the power of Quantum Computing to enhance power systems
                analysis, load balancing, network security, and real-time decision-making processes in the energy
                sector.
                <br/><br/>
            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default QuantumComputing;
