import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const GMD = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_gmd" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Geomagnetic Disturbance Mitigation in Power Systems </h2>
                <br/>
                UltimGrid Solutions offers specialized geomagnetic disturbance (GMD) consulting
                services for electrical grids, focusing on the assessment, mitigation, and management of the risks
                associated with geomagnetically induced currents (GICs). Our team of experts is committed to helping
                clients protect their critical infrastructure from the potentially devastating effects of GMD events,
                ensuring the uninterrupted operation of power systems. We provide comprehensive GMD vulnerability
                assessments, customized mitigation strategies, and advanced monitoring solutions, leveraging
                state-of-the-art technology and industry best practices to enhance grid resilience, minimize service
                disruptions, and safeguard the energy supply in the face of evolving natural hazards.

                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default GMD;
