import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const DigitalTwin = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_digitalTwin" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Digital Twin</h2>
                <br/>
                UltimGrid Solutions delivers state-of-the-art digital twin technology for the power
                industry, enabling our clients to optimize their operations, enhance performance, and reduce costs. Our
                team of experts is committed to developing comprehensive digital replicas of physical assets, processes,
                and systems, providing a powerful tool for data-driven decision-making, predictive maintenance, and
                performance analysis. By leveraging the latest advancements in digital twin technology, we help clients
                in the power sector to identify inefficiencies, anticipate potential issues, and implement proactive
                measures to ensure the highest levels of reliability, safety, and sustainability. Our digital twin
                solutions are designed to integrate seamlessly with existing infrastructure, empowering power industry
                stakeholders to harness the full potential of their data and unlock new opportunities for growth and
                innovation.

                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default DigitalTwin;
