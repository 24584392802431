import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";

const Reslience = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
        <div  className="row row_costume_reselience" style={{ minHeight: '100vh' }} >

            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >Power System Resilience Analysis</h2>
                <br/>
                Power system resilience refers to the ability of a power system to withstand and
                quickly recover from unexpected disruptions or events that may threaten the system\'s normal operations.
                These events could include natural disasters, such as hurricanes, earthquakes, floods, ice storms,
                wildfires, geomagnetic disturbances (GMDs), or man-made events such as cyber-attacks or physical
                attacks.
                <br/><br/>

                UltimGrid offers specialized consulting services for electrical grids, focusing on the assessment,
                mitigation, and management of the risks associated with natural events and disasters. Our team of
                experts is committed to helping clients protect their critical infrastructure from the potentially
                devastating effects of severe natural events, ensuring the uninterrupted operation of power systems. We
                provide comprehensive vulnerability assessments, customized mitigation strategies, and advanced
                monitoring solutions, leveraging state-of-the-art technology and industry best practices to enhance grid
                resilience, minimize service disruptions, and safeguard the energy supply in the face of evolving
                natural hazards.
                <br/><br/>

                At UltimGrid, we adhere to the highest standards of professionalism and quality in all of our services.
                We work closely with our clients to develop customized solutions that meet their unique needs and
                challenges. Our goal is to provide our clients with peace of mind, knowing that their power systems are
                robust, reliable, and resilient. We invite you to learn more about our power system study and analysis
                services and how we can help you protect your business from potential risks and disruptions. Contact us
                today to schedule a consultation with one of our experts.


                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default Reslience;
