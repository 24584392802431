import React from 'react';
import "../components/Components-CSS/Routes-CSS/Routes.css";
import "../components/Components-CSS/ltrAnime.css";
import {useEffect} from "react";
import ParticleImage from "./ParticleImage";
import Logo from "./Logo";
import Navboot_func from "../Nav_boot";

const BlockchainGrid = () => {

    useEffect(() => {
        // Add the animation class to the div after the page loads
        const divElement = document.getElementById('EMT'); // Replace 'yourDivId' with the actual id of your <div> element
        divElement.classList.add('leftToRightAnimation');
    }, []);

    const companyInfo = {
        name: 'UltimGrid Inc.',
        email: 'info@ultimgrid.com',
    };

    const bulletPoints = [
        'Insulation coordination',
        'Lightning and Switching surges',
        'Temporary Overvoltages (TOV)',
        'Switchgear and Transient Recovery Voltage (TRV)',
        'Wind and Photovoltaic Generation',
        'Microgrids and Energy Storage Systems',
        'Power Electronics',
        'Geomagnetic Disturbance (GMD) Analysis',
        'Ferroresonance',
        'Power Quality & Harmonics',
        'HVDC and FACTS',
        'Large Network Analysis with Inverter-Dominated DERs',
        'Interaction of Power Grids and Electrified Transportation Systems',
        'Electric Vehicles Powertrains and Charging Infrastructure',
        'Protection and Control',
        'Sub-Synchronous Resonance',
    ];


    return (
    <div  className="row row_costume_demandResponse" style={{ minHeight: '100vh' }} >
        <ParticleImage />



            <div className="col-1 p-0 m-0">
            </div>
            <div id="EMT" className="col-9 text-left p-5 m-5 " style={{fontSize:"22px"}} >
                <h2 style={{fontFamily:"Helvetica", fontWeight:"bold"}} >About Us</h2>
                <br/>
                UltimGrid Solutions is a trailblazing company focused on revolutionizing energy systems and
                transportation infrastructure by leveraging state-of-the-art technologies and innovative services. Our
                diverse range of solutions, including EV charging infrastructure, geomagnetic disturbance consulting,
                quantum computing integration, blockchain solutions for distributed energy markets, software-enabled
                solutions, Al-driven power system optimization, and digital twin technology, cater to the unique needs
                of stakeholders in the power and electric vehicle sectors. Committed to sustainability, reliability, and
                collaboration, our team of experts forms long-lasting partnerships with clients to help them navigate
                the rapidly evolving energy landscape. With continuous investment in research and development, UltimGrid
                Solutions strives to stay at the forefront of technological advancements, driving growth and innovation
                in the energy sector and fostering a more resilient, environmentally friendly, and interconnected energy
                ecosystem.
                <br/><br/>

            </div>
            <div className="col-1 p-0 m-0">
            </div>
        </div>
    );
};

export default BlockchainGrid
